<template>
  <div>
    <b-card>
      <h6>
        {{ `${$t('fields.result')} (${total})` }}
      </h6>
      <b-row
        align-v="center"
        class="my-2"
      >
        <b-col>
          <b-input-group>
            <b-form-input
              v-model="search"
              type="search"
              :placeholder="`${$t('fields.search')}...`"
            />
          </b-input-group>
        </b-col>
        <b-col
          cols="12"
          md="8"
        >
          <b-input-group>
            <date-time-picker
              @update="(date) => selectedFromDate = date"
            />
            <b-input-group-text>
              ถึง
            </b-input-group-text>
            <date-time-picker 
              @update="(date) => selectedToDate = date"
            />
          </b-input-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="4">
          <b-select v-model="selectedType">
            <b-form-select-option value="">
              เลือกประเภท
            </b-form-select-option>

            <b-form-select-option
              v-for="(type, typeI) in typeOptions"
              :key="typeI"
              :value="type.value"
            >
              {{ type.name }}
            </b-form-select-option>
          </b-select>
        </b-col>
        <b-col
          v-if="isOwner"
          cols="4"
        >
          <master-select-input 
            :value="selectedMaster"
            hide-label
            @update="onMasterIdChange"
          />
        </b-col>
        <b-col cols="4">
          <agent-select-input
            :value="selectedAgent"
            :master-id="selectedMaster"
            hide-label
            @update="onAgentIdChange"
          />
        </b-col>
      </b-row>
      <div class="text-right mb-2">
        <b-button
          variant="success"
          @click="fetchData"
        >
          <feather-icon icon="SearchIcon" />
          ค้นหา
        </b-button>
      </div>
      <!-- Table -->
      <b-table
        :items="transactions"
        :fields="fields"
        :busy="isLoading"
        responsive
        show-empty
      >
        <template #cell(createdAt)="data">
          {{ data.value | datetime }}
        </template>
        <template #cell(agent)="data">
          <span v-if="data.value.name">
            {{ data.value.name }}
          </span>
          <b-badge
            v-else
            variant="danger"
          >
            <feather-icon
              icon="XCircleIcon"
            />
            {{ $t('agent.no_agent') }}
          </b-badge>
        </template>
        <template #cell(amount)="data">
          <span :class="`text-${displayCreditTypeColor(data.item.type)}`">
            <span v-if="displayCreditTypeColor(data.item.type) === 'success'">
              +
            </span>
            <span v-else-if="displayCreditTypeColor(data.item.type) === 'danger'">
              -
            </span>
            {{ data.value | currency }}
          </span>
        </template>
        <template #cell(beforeAmount)="data">
          {{ data.value | currency }}
        </template>
        <template #cell(afterAmount)="data">
          {{ data.value | currency }}
        </template>
        <template #cell(type)="data">
          <b-badge :variant="displayCreditTypeColor(data.value)">
            {{ displayCreditType(data.value) }}
          </b-badge>
        </template>
        <template #cell(creator)="data">
          {{ data.value.username || '-' }}
        </template>
        <template #table-busy>
          <div class="text-center my-2">
            <b-spinner class="align-middle" />
          </div>
        </template>
        <template #empty="">
          <p class="text-center text-muted">
            {{ $t('messages.nothing_here') }}
          </p>
        </template>
      </b-table>
      <!-- pagination -->
      <b-row>
        <b-col md="4">
          <page-limit-select
            :value="limit"
            @update="onLimitChange"
          />
        </b-col>
        <b-col>
          <pagination-input
            :per-page="limit"
            :total="total"
            @update="(val) => currentPage = val"
          />
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
export default {
  page: {
    title: 'Transactions (Agent)',
  },
  data() {
    return {
      search: '',
      onSearchTimeout: '',
      selectedMaster: '',
      selectedAgent: '',
      selectedFromDate: '',
      selectedToDate: '',
      selectedType: '',
      typeOptions: [
        { name: 'ทั้งหมด', value: 'ALL' },
        { name: 'ยอดฝาก', value: 'DEPOSIT' },
        { name: 'ยอดถอน', value: 'WITHDRAW' },
      ],
      currentPage: 1,
      selectedLimit: 20,
      fields: [
        {
          key: 'createdAt',
          label: this.$t('fields.issued_date'),
          thStyle: {
            minWidth: '130px',
          },
        },
        {
          key: 'agent',
          label: this.$t('agent.name'),
          thStyle: {
            minWidth: '150px',
          },
        },
        {
          key: 'type',
          label: this.$t('credits.type'),
          thStyle: {
            minWidth: '120px',
          },
        },
        {
          key: 'beforeAmount',
          label: this.$t('credits.before_amount'),
          class: 'text-right',
          thStyle: {
            minWidth: '150px',
          },
        },
        {
          key: 'amount',
          label: this.$t('credits.credit_amount'),
          class: 'text-right',
          thStyle: {
            minWidth: '150px',
          },
        },
        {
          key: 'afterAmount',
          label: this.$t('credits.remain_credits'),
          class: 'text-right',
          thStyle: {
            minWidth: '150px',
          },
        },
        {
          key: 'creator',
          label: 'ผู้กระทำ',
          thStyle: {
            minWidth: '150px',
          },
        },
        {
          key: 'detail',
          label: `${this.$t('fields.remark')}`,
          thStyle: {
            minWidth: '250px',
          },
        },
      ],
    }
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.credit.isFetchingAgentCreditLogs,
    }),
    ...mapGetters(['isOwner','agentCredits', 'userInfo']),
    userId() {
      return this.userInfo.agentId
    },
    transactions() {
      return this.agentCredits.items || []
    },
    pagination() {
      return this.agentCredits.meta
    },
    limit() {
      return this.pagination.itemsPerPage
    },
    total() {
      return this.pagination.totalItems || 0
    },
  },
  watch: {
    currentPage(val) {
      if(val){
        this.$addPageToLocation(val)
        this.fetchData()
      }
    },
    search() {
      this.delaySearch();
    },
    selectedType(val) {
      if(val){
        this.fetchData()
      }
    },
  },
  created() {
    this.fetchData()
  },
  methods: {
    ...mapActions(['fetchAgentCreditLogs']),
    fetchData() {
      this.fetchAgentCreditLogs({
        limit: this.selectedLimit,
        page: this.currentPage,
        search: this.search, 
        masterId: this.selectedMaster, 
        agentId: this.selectedAgent, 
        type: this.selectedType,
        from: this.selectedFromDate || '',
        to: this.selectedToDate || '',
      })
    },
    onLimitChange(limit) {
      if(limit){
        this.selectedLimit = limit
        this.fetchData()
      }
    },
    onMasterIdChange(masterId) {
      this.selectedMaster = masterId
      this.fetchData()
    },
    onAgentIdChange(agentId) {
      this.selectedAgent = agentId
      this.fetchData()
    },
    delaySearch() {
      clearTimeout(this.onSearchTimeout)
      this.onSearchTimeout = setTimeout(this.fetchData(), 800)
    },
    displayCreditType(type) {
      switch (`${type}`) {
        case '0':
          return 'ยูสฝาก (-)'
        case '1':
          return 'ยูสถอน (+)'
        case '2':
          return 'ฝากตรงให้ยูสเซอร์ (-)'
        case '3':
          return 'ถอนตรงให้ยูสเซอร์ (+)'
        case '4':
          return 'ซื้อเครดิตจากมาสเตอร์ (+)'
        case '5':
          return 'ขายเครดิตให้เอเจ้น (-)'
        default:
          return `${type}`;
      }
    },
    displayCreditTypeColor(type) {
      switch (`${type}`) {
        case '1':
        case '3':
        case '4':
          return 'success'
        case '0':
        case '2':
        case '5':
          return 'danger'
        default:
          return 'light';
      }
    },
  },
}
</script>